<template>
    <div>
        <div class="nav-item dropdown dropdown-notifications dropdown-xs-down-full" v-b-hover="dataHoverMessage">
            <button class="nav-link btn-flush dropdown-toggle" type="button" data-toggle="dropdown" data-caret="false">
                <i class="material-icons icon-24pt">mail_outline</i>
                <span class="badge badge-notifications badge-accent" style="margin-top: -15px">{{
                        messages.length
                    }}</span>
            </button>
            <div class="dropdown-menu dropdown-menu-right custom-scroll" id="dropdown-message"
                 style="overflow-y: auto; max-height: calc(100vh - 200px);">
                <div class="position-relative ps">
                    <div class="list-group list-group-flush mb-0">

                        <router-link :to="'/mensagens/' + message.mentee_id"
                                     class="list-group-item list-group-item-action unread"
                                     v-for="message in messages" :key="'dropdown-item-message-' + message.id">
                            <span class="d-flex align-items-center mb-1">
                                <small class="text-black-50">{{ message.create_at }}</small>

                                <span class="ml-auto unread-indicator bg-accent" v-if="message.unread_count"></span>

                            </span>
                            <span class="d-flex">
                                <img height="40" :src="`${message.mentee.profile_image_base64}`"
                                     class="rounded-circle mr-2 my-auto"
                                     alt/>
                                <span class="flex d-flex flex-column">
                                    <strong class="text-black-100">{{ message.mentee.name }}</strong>
                                    <span class="text-black-70">{{ message.message }}</span>
                                </span>
                            </span>
                        </router-link>

                        <router-link to="/mensagens" class="list-group-item list-group-item-action unread pointer">
                            <button class="btn btn-outline-primary btn-block">Todas as Mensagens</button>
                        </router-link>

                    </div>
                </div>
            </div>
        </div>

        <!--        toasts de notificações de novas mensagens-->
        <div aria-live="polite" aria-atomic="true" style="position: fixed; min-height: 200px; right: 10px; top: 70px">
            <div style="position: absolute; top: 0; right: 0;">

                <div v-for="(toast, index) in toasts" class="toast" role="alert" aria-live="assertive"
                     aria-atomic="true" style="width: 300px; opacity: 1; padding: 10px 0" :key="'toast-' + toast.id">
                    <div class="toast-header d-flex justify-content-between" style="border-bottom: none">
                        <router-link :to="'/mensagens/' + toast.mentee_id" class="d-flex pointer">
                            <img :src="toast.mentee.profile_image_base64" alt="Imagem_mentorado"
                                 class="rounded-circle my-auto mr-2" height="40">
                            <div class="ms-2" style="display: grid">
                                <strong class="mr-auto">{{ toast.mentee.name }}</strong>
                                <small class="text-muted">{{ toast.message }}</small>
                            </div>
                        </router-link>
                        <button type="button" class="my-auto close" @click="removeToast(index)">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import {hoverHandler} from "@/components/composables/functions";
import http from "@/http";
import music from '@/assets/audio/audio.mp3'

export default {
    data() {
        return {
            audio: new Audio(music),
            toasts: [],
        }
    },
    methods: {
        removeToast(index) {
            /* eslint-disable */
            clearTimeout(this.toasts[index].timeoutId);
            this.toasts.splice(index, 1)
        },
        dataHoverMessage(isHovered) {
            hoverHandler(isHovered, 'dropdown-message');
        },

        getToast(message) {

            const timeoutId = setTimeout(() => {
                this.removeToast(this.toasts.length - 1);
            }, 15000);

            this.toasts = [
                message,
                ...this.toasts.slice(0, 2)
            ];
        },

        getMessages() {
            http.get('mentor/messages/chat-data')
                .then(response => {
                    this.$store.commit('api/setMessages', response.data);
                })
                .catch(e => {
                    console.error('Get Chat Data: ', e);
                })
        }
    },
    mounted() {
        this.getMessages();
        window.Echo.channel('chat')
            .listen('.message.sent', (e) => {
                if (e.message.mentor_id === this.currentUser.id && e.message.target === 'mentor' && this.$route.name !== 'chat') {
                    this.getToast(e.message)
                }

                if (e.message.mentor_id === this.currentUser.id) {
                    this.$store.commit('api/addMessage', e.message);
                    if (e.message.target !== 'mentee') {
                        this.audio.play().catch(error => {
                            console.error('Failed to play the audio:', error);
                        });
                    }
                }
            })
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },

        messages() {
            return this.$store.state.api.listMessages;
        }
    }
}

</script>
