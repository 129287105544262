<template>
    <div class="bg-white border-top-2 mt-auto" id="footer" :class="{ 'ml-256': sidebarOpen }">
        <div class="px-5 page-section d-flex flex-column">
            <b-row>
                <b-col col sm="6">
                    <img
                        src="@/assets/images/home.png"
                        alt="logo"
                        class="img-fluid logo-img"/>
                </b-col>
                <b-col col sm="6" class="mt-auto">                    
                    {{ new Date().getFullYear() }} © <a href="https://www.innovareti.com.br/"
                                                        class="pointer text-link text-end mt-auto"
                                                        target="_blank"><b>Innovare TI</b></a>.
                </b-col>
            </b-row>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },

    computed: {
        sidebarOpen() {
            return this.$store.state.sidebarOpen;
        }
    }
}
</script>
