<template>
    <div class="nav-item dropdown dropdown-notifications dropdown-xs-down-full"
         v-b-hover="dataHoverNotification">
        <button class="nav-link btn-flush dropdown-toggle" type="button" data-caret="false">
            <i class="material-icons">notifications_none</i>
            <span class="badge badge-notifications badge-accent">{{ no_read }}</span>
        </button>
        <div class="dropdown-menu dropdown-menu-right custom-scroll" id="dropdown-notification" @scroll="handleScroll"
             style="overflow-y: auto; max-height: calc(100vh - 200px);">
            <div class="position-relative ps">
                <div class="list-group list-group-flush mb-0">
                    <div v-if="Object.keys(notifications.data).length> 0">
                        <router-link :to="notification.url" :class="{'read' : !notification.read_at}"
                                     class="list-group-item list-group-item-action unread pointer"
                                     v-for="notification in notifications.data"
                                     :id="'dropdown-item-notification-' + notification.id"
                                     :key="'dropdown-item-notification-' + notification.id">
                            <span class="d-flex" @click="updateStatusNotification(notification)">
                                <img :src="notification.mentee.photo_url" alt="Imagem_mentorado"
                                     class="rounded-circle my-auto mr-2" height="40">
                                <span class="flex d-flex flex-column">

                                    <span class="flex d-flex flex-column">
                                        <strong class="text-black-100">{{ notification.mentee.name }}</strong>
                                        <span class="text-black-70">{{ notification.description }}</span>
                                        <small class="text-black-50 text-end">
                                            {{ getDateNotifications(notification.created_at) }}
                                        </small>
                                    </span>
                                </span>
                            </span>
                        </router-link>
                    </div>
                    <div v-else>
                        <div class="list-group-item list-group-item-action unread pointer">
                            Sem notificações
                        </div>
                    </div>

                    <!--                        <router-link to="/notificacoes" class="list-group-item list-group-item-action unread pointer">-->
                    <!--                            <button class="btn btn-outline-primary btn-block">Todas as Notificações</button>-->
                    <!--                        </router-link>-->

                </div>
            </div>
        </div>
    </div>
</template>

<script>

import http from "@/http";
import {getDateNotifications} from "@/components/composables/masks";
import {hoverHandler} from "@/components/composables/functions";

export default {
    data() {
        return {
            notifications: {
                data: []
            },
            page: 1,
            query: true,
            no_read: 0,
        }
    },
    methods: {
        getDateNotifications,

        updateStatusNotification(notification) {
            http.get('mentor/notification/update-status/' + notification.id)
                .then(() => {
                    document.getElementById('dropdown-item-notification-' + notification.id).classList.remove('read');
                    --this.no_read;
                })
                .catch(e => {
                    console.error('Update Notifications: ', e.response);
                })
        },

        handleScroll(event) {
            const el = event.target;
            if (parseInt(el.scrollHeight - el.scrollTop) === el.clientHeight) {
                if (this.query && this.page * 4 < this.notifications.count) {
                    this.query = false;
                    this.getNotifications(++this.page);
                }
            }
        },

        dataHoverNotification(isHovered) {
            hoverHandler(isHovered, 'dropdown-notification');
        },


        getNotifications() {
            http.get(`mentor/notifications?page=${this.page}`)
                .then(response => {
                    let newValue = this.notifications.data;
                    newValue.push(...response.data.notifications.data);
                    this.notifications = response.data.notifications;
                    this.notifications.data = newValue;
                    this.no_read = response.data.no_read;
                    this.query = true;
                })
                .catch(e => {
                    console.error('Get Notifications: ', e);
                })
        }
    },

    mounted() {
        this.getNotifications();
    },
}
</script>

<style scoped>

.dropdown-notifications .badge-notifications {
    margin-left: -0.9rem !important;
    margin-top: -1rem !important;
}

</style>
