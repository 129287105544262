<template>
    <div class="mdk-header-layout js-mdk-header-layout">

        <!-- Header -->
        <div id="header"
             class="mdk-header js-mdk-header mb-0"
             data-fixed>
            <div class="mdk-header__content">

                <!-- Navbar -->
                <div class="navbar navbar-expand pr-0 navbar-light bg-white navbar-shadow"
                     id="default-navbar"
                     data-primary>

                    <!-- Navbar Toggler -->
                    <button class="navbar-toggler w-auto mr-16pt d-block rounded-0"
                            type="button" @click="toggleSidebar"
                            data-toggle="sidebar">
                        <span class="material-icons">short_text</span>
                    </button>

                    <!-- // END Navbar Toggler -->

                    <img
                        src="@/assets/images/home.png"
                        alt="logo"
                        class="img-fluid logo-img"/>


                    <span class="d-none d-md-flex align-items-center mr-16pt ml-3">

                            <span class="avatar avatar-sm mr-12pt">

                                <span class="avatar-title rounded navbar-avatar"><i
                                    class="material-icons text-primary">trending_up</i></span>

                            </span>

                            <small class="flex d-flex flex-column">
                                <strong class="navbar-text-100">Receitas</strong>
                                <span class="navbar-text-50 text-center">{{
                                        maskMoneyTable(cards.value_total_sales)
                                    }}</span>
                            </small>
                        </span>
                    <span class="d-none d-md-flex align-items-center mr-16pt">

                            <span class="avatar avatar-sm mr-12pt">

                                <span class="avatar-title rounded navbar-avatar"><i
                                    class="material-icons text-primary">receipt</i></span>

                            </span>

                            <small class="flex d-flex flex-column">
                                <strong class="navbar-text-100">Vendas</strong>
                                <span class="navbar-text-50 text-center">{{ cards.total_sales }}</span>
                            </small>
                        </span>
                    <div class="flex"></div>
                    <NavBar/>
                </div>
            </div>
        </div>
        <div class="mdk-header-layout__content">
            <div class="mdk-drawer-layout js-mdk-drawer-layout"
                 data-responsive-width="992px">
                <div class="mdk-drawer-layout__content page-content">
                    <div class="px-5">
<!--                        <div class="page-section">-->
                        <div class="page__container pt-5 container page__container">

                            <!-- Corpo da Página -->
                            <slot/>
                        </div>
                    </div>
                </div>

                <Menu v-if="this.$store.state.sidebarOpen"/>

            </div>
            <Footer/>
        </div>
        <SpinnerXl/>
    </div>
</template>

<script>
import NavBar from "./nav-bar.vue";
import Footer from "./footer.vue";
import Menu from "./menu.vue";
import SpinnerXl from "@/components/base/spinner-xl.vue";
import {maskMoneyTable} from "../composables/masks";
import {mapActions} from 'vuex';


export default {
    components: {
        NavBar,
        Footer,
        Menu,
        SpinnerXl
    },
    data() {
        return {}
    },
    methods: {
        maskMoneyTable,
        ...mapActions(['toggleSidebar']),
    },

    mounted() {
        this.$store.dispatch('api/setCards');
    },

    computed: {
        cards() {
            return this.$store.state.api.cards
        }
    }
}
</script>
